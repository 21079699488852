import React from "react";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./compoonents/Footer";
import Header from "./compoonents/Header";
import Banner from "./compoonents/Banner";
import Project from "./compoonents/Project";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header></Header>
              <Banner></Banner>
              <Project></Project>
              
              <Footer></Footer>
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
