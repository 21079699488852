import { Box } from "@mui/material";
import React from "react";
import banner_one from "../assets/banners/banner_one.jpg";
import banner_two from "../assets/banners/banner_two.jpeg";
import banner_three from "../assets/banners/banner_three.jpg";
import banner_one_gif from "../assets/banners/banner.gif"
import banner_two_gif from "../assets/banners/banner_two.gif"
import banner_three_gif from "../assets/banners/banner_three.gif"
function Banner() {
  return (
    <Box 
    marginTop={1}
    marginBottom={1}
    // p={1}
    >
      <img src={banner_three_gif} alt="banner" height={500} width={"100%"} />
    </Box>
  );
}

export default Banner;
