import { Box, Paper } from "@mui/material";
import React from "react";
import "./Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Paper elevation={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        p={2}
        sx={{ backgroundColor: "#364545" }}
      >
        <div>
          <div></div>
          {/* <div>Nilesh One</div>
          <div>Nilesh One</div>
          <div>Nilesh One</div>
          <div>Nilesh One</div> */}
        </div>
        <div className="Right_Container">
          <div className="Social_Media_Container">
            <div className="Footer_Item">
              <LinkedInIcon
                onClick={() => {
                  window.open(
                    "https://www.linkedin.com/company/ackermanin/",
                    "_blank"
                  );
                }}
              ></LinkedInIcon>
            </div>
            <div className="Footer_Item">
              <InstagramIcon></InstagramIcon>
            </div>
            <div className="Footer_Item">
              <TwitterIcon></TwitterIcon>
            </div>
            <div className="Footer_Item">
              <YouTubeIcon></YouTubeIcon>
            </div>
            <div className="Footer_Item">
              <PinterestIcon></PinterestIcon>
            </div>
          </div>
          <div className="Copy_Right_Container">
            <small>@2022 AckerMan Hub</small>
          </div>
        </div>
      </Box>
    </Paper>
  );
}

export default Footer;
