import React from "react";
import { Paper, Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Project.css";

import ai_ml_img from "../assets/ml.jpg";
import rest_api_img from "../assets/API.png"
import mobile_img from "../assets/mobile.png"
import website_img from "../assets/web.jpg"
const Card = (props) => (
  <div className="card">
    <img src={props.imgUrl} alt={props.alt || "Image"} />
    <div className="card-content">
      <h3>{props.title}</h3>
      <p>{props.content}</p>
    </div>
  </div>
);
const CardContainer = (props) => (
  <div className="cards-container">
    {props.cards.map((card) => (
      <Card title={card.title} content={card.content} imgUrl={card.imgUrl} />
    ))}
  </div>
);
const Project = () => {
  const cardsData = [
    {
      id: 1,
      title: "Mobile App Development",
      content: "React-Native ,Expo, Quasar, Flutter ",
      imgUrl: mobile_img,
    },
    {
      id: 2,
      title: "Website Development",
      content: "React, Vue, Quasar, Node, MySql",
      imgUrl: website_img,
    },
    {
      id: 3,
      title: "Rest API",
      content: "Java, Node, PHP ,Python",
      imgUrl: rest_api_img,
    },
    {
      id: 4,
      title: "AI & Machine Learning",
      content: "TensorFlow, PyTorch, Scikit-learn etc...",
      imgUrl: ai_ml_img,
    },
    // {
    //   id: 5,
    //   title: "CARD 5",
    //   content: "Reed Richards",
    //   imgUrl: "https://unsplash.it/202/200",
    // },
    // {
    //   id: 6,
    //   title: "CARD 6",
    //   content: "Wade Wilson",
    //   imgUrl: "https://unsplash.it/200/199",
    // },
    // {
    //   id: 7,
    //   title: "CARD 7",
    //   content: "Peter Quill",
    //   imgUrl: "https://unsplash.it/199/199",
    // },
    // {
    //   id: 8,
    //   title: "CARD 8",
    //   content: "Steven Rogers",
    //   imgUrl: "https://unsplash.it/199/200",
    // },
    // {
    //   id: 9,
    //   title: "CARD 9",
    //   content: "Bruce Banner",
    //   imgUrl: "https://unsplash.it/200/198",
    // },
    // {
    //   id: 10,
    //   title: "CARD 10",
    //   content: "Vincent Strange",
    //   imgUrl: "https://unsplash.it/198/199",
    // },
  ];

  return (
    <>
      <Paper elevation={3}>
        <Box
          padding={3}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h2>Projects & Services</h2>
        </Box>
        <CardContainer cards={cardsData} />
      </Paper>
    </>
  );
};

export default Project;
