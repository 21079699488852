import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import logo from "../assets/AckerMan Logo.png";
import { Box, Paper, Drawer, List, ListItem, ListItemText, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";
import logo_two from '../assets/Ackerman.png';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '75vw', // Set the width to 75% of the viewport width
  },
  listItem: {
    padding: '10px 20px',
  },
  listItemText: {
    marginTop: '3px',
    marginLeft: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
}));



function Header() {
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 600);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Paper elevation={3} className="header-paper">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        {isMobile && (
          // Mobile Menu Button
          <IconButton className="Drawer_Icon" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
        )}
        <Box display="flex" sx={{justifyContent:'center'}} marginLeft={"20px"} className="Logo_Container">
          <img
            src={logo}
            alt="ackerman-logo"
            width={180}
            height={35}
            style={{ cursor: "pointer" }}
          />
        </Box>
        {isMobile ? null : (
          // Desktop Navigation Items
          <Box
            display="flex"
            // marginRight={10} marginLeft={10}
          >
            <div className="Header_Item">Services</div>
            <div className="Header_Item">About us</div>
            <div className="Header_Item">Contact Us</div>
          </Box>
        )}
      </Box>
      {isMobile && (
        // Drawer for Mobile
        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer}
          classes={{ paper: classes.drawer }}
        >
          <List>
            <Paper sx={{ marginBottom: "20px" }}>
              <ListItem>
                <div className="Drawer_Header">
                  <img src={logo_two} height={"30px"} width={"30px"}></img>
                  <div style={{ marginLeft: "30px",fontWeight:500,fontSize:'20px' }}>Ackerman Hub</div>
                </div>
              </ListItem>
            </Paper>
            <ListItem button className={classes.listItem}>
              <div className="Drawer_Row">
                <MiscellaneousServicesIcon></MiscellaneousServicesIcon>
                <ListItemText
                  primary={<strong>Services</strong>}
                  className={classes.listItemText}
                />
              </div>
            </ListItem>
            <ListItem button className={classes.listItem}>
              <div className="Drawer_Row">
                <Diversity3OutlinedIcon></Diversity3OutlinedIcon>
                <ListItemText
                  primary={<strong>About Us</strong>}
                  className={classes.listItemText}
                />
              </div>
            </ListItem>
            <ListItem button className={classes.listItem}>
              <div className="Drawer_Row">
                <ConnectWithoutContactOutlinedIcon></ConnectWithoutContactOutlinedIcon>
                <ListItemText
                  primary={<strong>Contact Us</strong>}
                  className={classes.listItemText}
                />
              </div>
            </ListItem>
          </List>
        </Drawer>
      )}
    </Paper>
  );
}

export default Header;
